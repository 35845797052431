<template>
  <div class="container-xxl py-24">
    <MetaTags
      :title="isEditing ? `${formProfession.title} ${formCity.title} редактировать` : 'Создание видеовакансии'"
      :description="isEditing ? `Редактировать вакансию ${formProfession.title} ${formCity.title}` : 'Запишите видео на подготовленные вопросы и опубликуйте вакансию'"
    />
    <AppBreadcrumbs :links="getBreadcrumbs()" />
    <div
      ref="refPageTitle"
      class="d-md-flex justify-content-md-between align-items-md-center mb-15"
    >
      <h1 class="heading-header-1 d-inline-block mb-md-0">
        {{ isEditing ? 'Редактирование вакансии' :'Создание вакансии' }}
      </h1>
    </div>
    <AppSteps
      class="mb-24"
      :steps="[
        {
          title: 'Информация',
          description: 'Заполните немного полей о вакансии. Следующий шаг будет запись вопросов.',
          isValid: isInformationValid,
        },
        {
          title: 'Запись вопросов',
          description: 'Запишите короткие ответы на вопросы. Следующий шаг будет доп.настройки.',
          isValid: isRecordsValid,
        },
        {
          title: 'Дополнительно',
          description: 'Этот шаг можно пропустить.',
          isValid: isAdditionalSettingsValid,
        },
        {
          title: 'Публикация',
          description: 'Проверьть информацию и видео. Нажмите опубликовать.',
        },
      ]"
      :active-step="activeTab"
      @change-step="changeTab"
      @next-step="nextTab"
    />
    <div
      class="mx-auto"
      style="max-width: 1000px;"
    >
      <div class="row">
        <div
          ref="vacancyBlock"
          class="col-12"
        >
          <WindowItem v-show="activeTab === 0">
            <div class="row">
              <div class="col-12 col-md-3 mb-24">
                <span class="fw-bold">Город</span>
                <SelectLayout
                  v-model="formCity"
                  class="form-control mt-10"
                  target="cities"
                  :class="{ 'is-invalid': $v.formCity.$error }"
                  :url-getter="(title) => '/cities?title='+title"
                  :clearable="false"
                  @option:selected="getMetro"
                />
              </div>
              <div class="col-12 col-md-6 mb-24">
                <span class="fw-bold">Адрес</span> <small class="text-gray">- не обязательно</small>
                <SelectLayout
                  v-model="formAddress"
                  class="form-control mt-10"
                  target="addresses"
                  :url-getter="(val) => `fias?query=${encodeURI(val)}&kladrId=${encodeURI(formCity?.kladrId)}`"
                  :filterable="false"
                  :disabled="formCity === null"
                />
              </div>
              <div class="col-12 col-md-3 mb-24">
                <span class="fw-bold">Метро</span> <small class="text-gray">- не обязательно</small>
                <SelectLayout
                  v-model="formMetro"
                  class="form-control mt-10"
                  label="name"
                  :disabled="metroLoading === false && metroList.length === 0"
                  :options="metroList"
                  :loading="metroLoading"
                >
                  <template #option="{ option }">
                    {{ option.name }}
                    <small
                      class="d-flex align-items-center"
                      style="font-size: .8rem;"
                    >
                      <span
                        class="rounded-circle d-block me-5"
                        style="height: .5em; width: .5em;"
                        :style="{ 'background-color': '#' + option.line.color }"
                      />
                      {{ option.line.name }}
                    </small>
                  </template>
                </SelectLayout>
              </div>

              <div class="col-12 col-md-6 mb-24">
                <span class="fw-bold">Профессия</span>
                <SelectLayout
                  v-model="formProfession"
                  class="form-control mt-10"
                  :class="{ 'is-invalid': $v.formProfession.$error }"
                  :url-getter="(title) => '/professions?title='+title"
                  target="professions"
                  @input-changed="(val) => { formProfession = val }"
                />
              </div>
              <div class="col-12 col-md-6 mb-24">
                <span class="fw-bold">Опыт работы в данной должности</span>
                <SelectLayout
                  v-model="formExperience"
                  class="form-control mt-10"
                  :class="{ 'is-invalid': $v.formExperience.$error }"
                  :clearable="false"
                  :reduce="(val) => val.id"
                  :options="ExperienceList"
                />
              </div>

              <div
                v-if="false"
                class="col-12 col-md-6 mb-24"
              >
                Тип занятости
                <SelectLayout
                  v-model="formTypeEmployment"
                  class="form-control pt-5"
                  :class="{ 'is-invalid': $v.formTypeEmployment.$error }"
                  :clearable="false"
                  :reduce="(val) => val.id"
                  :options="typeEmployment"
                />
              </div>
              <div class="col-12 col-md-6 mb-24">
                <span class="fw-bold">График работы</span>
                <div class="d-flex flex-wrap flex-md-row flex-column mt-10">
                  <CheckBox
                    v-for="(item) in schedules"
                    class="col-md-6 col-12"
                    :val="item.type"
                    :is-checked="formSchedules.indexOf(item.type) !== -1"
                    @change="check(item.type, $event.target.checked)"
                  >
                    {{ item.name }}
                  </CheckBox>
                </div>
                <div
                  class="invalid-feedback"
                  :class="$v.formSchedules.$error ? 'd-block' : ''"
                >
                  Выберите хотя бы один пункт
                </div>
              </div>

              <label class="col-12 col-md-3 mb-24">
                <span class="fw-bold">Зарплата от</span> <small class="text-gray">- не обязательно</small>
                <input
                  class="form-control rounded-20 mt-10"
                  :class="{ 'is-invalid': $v.formSalaryMin.$error }"
                  type="text"
                  :value="getSalaryString(formSalaryMin)"
                  @input="({ target }) => { formSalaryMin = getSalaryNumber(target.value) }"
                >
              </label>
              <label class="col-12 col-md-3 mb-24">
                <span class="fw-bold">Зарплата до</span> <small class="text-gray">- не обязательно</small>
                <input
                  class="form-control rounded-20 mt-10"
                  :class="{ 'is-invalid': $v.formSalaryMax.$error }"
                  type="text"
                  :value="getSalaryString(formSalaryMax)"
                  @input="({ target }) => { formSalaryMax = getSalaryNumber(target.value) }"
                >
              </label>

              <label class="col-12 mb-24">
                <span class="fw-bold">О вакансии ({{ getCountChar }}/{{ maxChar }})</span> <small> - <span class="text-gray">не обязательно</span></small>
                <textarea
                  v-model="$v.formDescription.$model"
                  class="form-control rounded-20 mt-10"
                  :class="$v.formDescription.$error ? 'is-invalid' : ''"
                />
              </label>
            </div>

            <div class="d-flex justify-content-around">
              <AppButton
                class="invisible"
                :variant="BtnVariant.Tertiary"
              >
                <template #prepend-icon>
                  <svg class="w-100 h-100 flip-horizontal">
                    <use xlink:href="/public/icons/main.svg#east" />
                  </svg>
                </template>
                Назад
              </AppButton>
              <AppButton
                @click="nextTab"
              >
                Далее
                <template #append-icon>
                  <svg class="w-100 h-100">
                    <use xlink:href="/public/icons/main.svg#east" />
                  </svg>
                </template>
              </AppButton>
            </div>
          </WindowItem>
          <VideoRecord
            v-show="activeTab === 1"
            :questions="formVacancyQuestions"
            :is-active="activeTab === 1"
            :active-video-hub="true"
            :active-slot="activeTab === 3"
            :is-show="activeTab === 1"
            :is-update="isEditing"
            :stream="stream"
            :is-camera-denied="isCameraDenied"
            @set-record-blob="setRecordBlob"
            @start-record="startRecord"
            @change-company-video="changeCompanyVideo"
            @last-skip="nextTab"
            @webm-supported="setWebmSupported"
          >
            <template #nextStep>
              <AppButton
                class="fw-bold"
                @click="nextTab"
              >
                Далее
              </AppButton>
            </template>
          </VideoRecord>
          <WindowItem v-show="activeTab === 2">
            <div
              v-if="false"
              class="col-12 mb-24"
            >
              Ответственные сотрудники <small class="text-gray">- не обязательно</small>
              <SelectLayout
                v-model="formAssignedInterviewers"
                class="form-control"
                :disabled="!isDirector"
                :url-getter="val => `/companies/${companyId}/employees?full_name=${encodeURIComponent(val)}&is_accept=true`"
                target="employees"
                :label-fun="option => (option.name !== null ? option.name + ' ' + option.surname : option.email)"
                :options="interviewersDefault"
                :filterable="false"
                multiple
              />
            </div>
            <div class="col-12 mb-24">
              <label class="fw-bold mb-10">Дополнительная связь</label>
              <div
                v-for="(item) in socials"
                class="mb-10"
              >
                <div class="d-flex align-items-center">
                  <svg
                    v-show="item.icon !== undefined"
                    height="25"
                    width="25"
                    class="me-5 text-primary"
                  >
                    <use :xlink:href="item.icon !== undefined ? `/public/icons/${item.icon}.svg#${item.icon}` : ''" />
                  </svg>
                  <span class="fs-14 me-5">{{ item.name }}</span>
                  <svg
                    v-show="formSocial[item.key] === undefined"
                    height="30"
                    width="30"
                    type="button"
                    class="text-primary"
                    @click="item.key === 'email' ? formSocial[item.key] = client.email : formSocial[item.key] = null"
                  >
                    <use xlink:href="/public/icons/auth-sprite.svg#add" />
                  </svg>
                </div>
                <div
                  v-if="formSocial[item.key] !== undefined"
                  class="d-flex"
                >
                  <AppInput
                    v-if="item.key === 'email'"
                    v-model="$v.formSocial[item.key].$model"
                    name="email"
                    type="email"
                    class="w-100 me-15"
                    error-message="E-Mail не корректен"
                    :is-invalid="$v.formSocial[item.key].$error"
                  />
                  <AppInput
                    v-else
                    v-model="$v.formSocial[item.key].$model"
                    name="phone"
                    type="tel"
                    class="w-100 me-15"
                    maska="+7 (F##) ###-##-##"
                    :maska-tokens="{
                      F: { pattern: '[012345679]' },
                    }"
                    error-message="Номер телефона не корректен"
                    :is-invalid="$v.formSocial[item.key].$error"
                  />
                  <svg
                    height="40"
                    width="40"
                    class="mt-17"
                    type="button"
                    @click="delete formSocial[item.key]"
                  >
                    <use xlink:href="/public/icons/main.svg#close" />
                  </svg>
                </div>
              </div>
            </div>
            <div class="col-12 mb-24">
              <label class="fw-bold mb-10">Свои вопросы для кандидата</label>
              <p class="fs-14 text-gray">
                Можно пригласить кандидата на запись видео-ответов по данным вопросам
              </p>
              <div class="d-flex align-items-center">
                <span class="me-5 text-decoration-underline">Добавить вопрос</span>
                <svg
                  height="30"
                  width="30"
                  type="button"
                  class="text-primary"
                  @click="addQuestion"
                >
                  <use xlink:href="/public/icons/auth-sprite.svg#add" />
                </svg>
              </div>
              <div class="d-flex flex-column gap-24">
                <div
                  v-for="(question, index) in formQuestions"
                  :key="index"
                  class="row"
                >
                  <div class="col-12 col-md-auto flex-md-grow-1">
                    <label class="text-gray w-100">
                      Вопрос {{ index + 1 }}
                      <input
                        v-model.trim="$v.formQuestions.$model[index].question"
                        type="text"
                        class="form-control"
                        :class="{
                          'is-invalid': question.question === '',
                        }"
                      >
                    </label>
                  </div>
                  <div class="col-auto flex-grow-1 flex-md-grow-0 mt-md-12 mt-0">
                    <div class="text-gray w-100">
                      <SelectLayout
                        v-model="question.time"
                        class="form-control mt-10"
                        style="min-width: 185px; width: 100%"
                        :clearable="false"
                        :reduce="val => val.value"
                        :options="[{title: '10 сек.', value: 10},{title: '30 сек.', value: 30},{title: '60 сек.', value: 60}]"
                        @input-changed="(val) => { question.time = val }"
                      />
                    </div>
                  </div>
                  <div class="col-auto d-flex align-items-center mt-md-12 mt-0">
                    <svg
                      height="40"
                      width="40"
                      class="mt-10"
                      type="button"
                      @click="deleteQuestion(index)"
                    >
                      <use xlink:href="/public/icons/main.svg#close" />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-around">
              <AppButton
                class="invisible"
                :variant="BtnVariant.Tertiary"
                @click="changeTab(activeTab - 1)"
              >
                <template #prepend-icon>
                  <svg class="w-100 h-100 flip-horizontal">
                    <use xlink:href="/public/icons/main.svg#east" />
                  </svg>
                </template>
                Назад
              </AppButton>
              <AppButton
                @click="nextTab"
              >
                Далее
                <template #append-icon>
                  <svg class="w-100 h-100">
                    <use xlink:href="/public/icons/main.svg#east" />
                  </svg>
                </template>
              </AppButton>
            </div>
          </WindowItem>
          <WindowItem v-show="activeTab === 3">
            <div class="mb-20">
              <p class="fw-bold mb-10">
                Видимость
              </p>
              <div class="d-flex align-items-center">
                <SelectLayout
                  v-model="isOnlyLink"
                  class="form-control"
                  style="width: 225px;"
                  :options="[
                    {
                      value: false,
                      title: 'Видно всем',
                    },
                    {
                      value: true,
                      title: 'Видно по ссылке',
                    }
                  ]"
                  :reduce="(val) => val.value"
                  :clearable="false"
                />
                <AppButton
                  v-show="isOnlyLink && isEditing"
                  class="ms-15"
                  title="Скопировать ссылку на видеорезюме"
                  :variant="BtnVariant.Tertiary"
                  is-icon
                  @click="copyLink"
                >
                  <svg class="h-100 w-100">
                    <use xlink:href="/public/icons/main.svg#link" />
                  </svg>
                </AppButton>
                <AppButton
                  v-if="isEditing"
                  class="position-relative ms-15"
                  :disabled="isSending"
                  :title="isPublished ? 'Переместить в архив' : 'Восстановить из архива'"
                  :variant="BtnVariant.Tertiary"
                  @click="archiving()"
                >
                  {{ isPublished ? 'В архив' : 'Восстановить' }}
                </AppButton>
              </div>
            </div>
            <div class="mb-20">
              <p class="fw-bold mb-10">
                Город
              </p>
              <div>
                {{ formCity?.title }}
              </div>
            </div>
            <div class="mb-20">
              <p class="fw-bold mb-10">
                Адрес
              </p>
              <div>
                {{ formAddress }}
              </div>
            </div>
            <div class="mb-20">
              <p class="fw-bold mb-10">
                Профессия
              </p>
              <div>
                {{ formProfession?.title }}
              </div>
            </div>
            <div class="mb-20">
              <p class="fw-bold mb-10">
                Опыт работы в данной должности
              </p>
              <div>
                {{ ExperienceList.find(val => val.id === formExperience).title }}
              </div>
            </div>
            <div
              v-if="false"
              class="mb-20"
            >
              <p class="fw-bold mb-10">
                Тип занятости
              </p>
              <div>
                {{ typeEmployment.find(val => val.id === formTypeEmployment).title }}
              </div>
            </div>
            <div class="mb-20">
              <p class="fw-bold mb-10">
                График работы
              </p>
              <div>
                {{ getScheduleList }}
              </div>
            </div>
            <div class="mb-20">
              <p class="fw-bold mb-10">
                Зарплата
              </p>
              <div>
                <span v-if="formSalaryMin === null && formSalaryMax === null">з/п не указана</span>
                <template v-else>
                  <template v-if="formSalaryMin !== null">
                    от <span class="fw-bold">{{ formSalaryMin.toLocaleString() }}</span>
                  </template>
                  <template v-if="formSalaryMax !== null">
                    до <span class="fw-bold">{{ formSalaryMax.toLocaleString() }}</span>
                  </template>
                  Руб
                </template>
              </div>
            </div>
            <div class="mb-20">
              <p class="fw-bold mb-10">
                О вакансии
              </p>
              <div>
                {{ formDescription }}
              </div>
            </div>
            <div class="mb-20">
              <p class="fw-bold mb-10">
                Запись вопросов
              </p>
              <AppButton
                :variant="BtnVariant.Tertiary"
                @click="itemInfoModalShow = true"
              >
                Смотреть
              </AppButton>
            </div>
            <AppButton
              class="d-flex mx-auto fw-bold"
              @click="save"
            >
              Сохранить
            </AppButton>
          </WindowItem>
        </div>
      </div>
    </div>
    <RestoreResponsesModal />

    <VueFinalModal
      v-model="itemInfoModalShow"
      display-directive="show"
      :content-class="isMobile ? '' : 'modal-dialog modal-dialog-centered justify-content-center'"
    >
      <div
        class="modal-content"
        :class="{
          'rounded-20 overflow-hidden': !isMobile
        }"
        :style="{
          height: wrapHeight + 'px',
          width: wrapWidth + 'px',
        }"
      >
        <VideoPlayer
          :arrow-show="true"
          :only-step="true"
          is-hidden-timer
          is-reflection
          :answers="getVideoData"
          :wrap-height="wrapHeight"
          :wrap-width="wrapWidth"
          @close="itemInfoModalShow = false"
        />
      </div>
    </VueFinalModal>
  </div>
</template>

<script setup lang="ts">
import SelectLayout from "~/components/inputs/selectLayout.vue";
import { useClient } from "~/stores/client";
import type { Question } from "~/entities/Question";
import { useVuelidate } from "@vuelidate/core";
import { useClipboard } from "@vueuse/core";
import {numeric, required, maxLength, minLength, email} from "@vuelidate/validators";
import { phone } from "~/composables/validator";
import { useApiVacancies } from "~/composables/api/vacancies";
import type { CompanyVideo } from "~/entities/CompanyVideo";
import type { QuestionRecord } from "~/entities/QuestionRecord";
import {useModal, VueFinalModal} from "vue-final-modal";
import type { Profession } from "~/entities/profession";
import { useApiRecordUpload } from "~/composables/api/RecordUpload";
import RestoreResponsesModal from "~/components/modal/RestoreResponsesModal.vue";
import { BtnVariant } from "~/composables/enums/BtnVariant";
import { ExperienceList } from "~/entities/Experience";
import type { UploadVideoData } from "~/entities/UploadVideoData";
import AppSteps from "~/components/steps/AppSteps.vue";
import AppInput from "~/components/inputs/AppInput.vue";
import CheckBox from "~/components/inputs/checkBox.vue";
import {useCheckMobile} from "~/composables/CheckMobile";
import type {VideoData} from "~/entities/videoData";
import { useVideoSettings } from "~/composables/videoSettings";

const config = useRuntimeConfig();
const { $toast } = useNuxtApp();
const route = useRoute();
const router = useRouter();
const client = useClient();
const apiVacancies = useApiVacancies();
const apiRecordUpload = useApiRecordUpload();
const { copy, copied } = useClipboard({ source: `${config.public.protocol}://${config.public.domain}/vacancies/${route.params.vacancy_id}` });
const useRestoreResponsesModal = useModal({
  component: RestoreResponsesModal,
  attrs: {
    onConfirm: () => useRestoreResponsesModal.close(),
    onArchive: () => archiving(false),
    onRestoreResponses: () => archiving(true),
  },
});

//-----TYPE-----\\
type Salary = {
  min: number | null;
  max: number | null
};
type Social = {
  email: string | undefined,
  phone: string | undefined,
  telegram: string | undefined,
  whatsapp: string | undefined,
  viber: string | undefined
};

//-----VARIABLES-----\\
const maxChar = 300;
const typeEmployment = [
  {
    id: 0,
    title: 'Полная занятость',
  },
  {
    id: 1,
    title: 'Частичная занятость',
  },
  {
    id: 2,
    title: 'Фриланс',
  },
  {
    id: 3,
    title: 'Стажировка',
  },
];
const schedules = [
  {
    type: 1,
    name: 'Полный рабочий день',
  },
  {
    type: 2,
    name: 'Сменный график',
  },
  {
    type: 3,
    name: 'Свободный график',
  },
  {
    type: 4,
    name: 'Частичная занятость'
  },
  {
    type: 5,
    name: 'Удаленная работа',
  },
  {
    type: 6,
    name: 'Вахта',
  },
];
const socials = [
  {
    name: 'EMAIL',
    key: 'email',
    icon: 'alternate-email'
  },
  {
    name: 'Телефон',
    key: 'phone',
    icon: 'call'
  },
  {
    name: 'Whatsapp',
    key: 'whatsapp',
    icon: 'whatsapp'
  },
  {
    name: 'Telegram',
    key: 'telegram',
    icon: 'telegram-blue'
  },
  {
    name: 'Viber',
    key: 'viber',
    icon: 'viber'
  }
];
let isWebmSupported = false;
let isMobile = false;
let wrapHeight = 0;
let wrapWidth = 0;

//-----STATE-----\\
const vacancyBlock = ref();
const refPageTitle = ref();
const vacancyId = ref<string|undefined>(route.params.vacancy_id as string|undefined);
const companyId = ref<string>(route.params.id as string);
const isSending = ref<boolean>(false);
const isOnlyLink = ref<boolean>(false);
const activeTab = ref<number>(route.query.isRewrite !== undefined ? 1 : 0);
const formVacancyQuestions = ref<QuestionRecord[]>([]);
const isArchived = ref<boolean>(false);
const isPublished = ref<boolean>(false);
const isEditing = ref<boolean>(vacancyId.value !== undefined);
const formCity = ref<{ id: number, title: string, kladrId: number }|null>(null);
const formAddress = ref<string|null>(null);
const formMetro = ref(null);
const formSalaryMin = ref<number|null>(null);
const formSalaryMax = ref<number|null>(null);
const formTypeEmployment = ref<number>(1);
const formSchedules = ref<number[]>([1]);
const formProfession = ref<Profession|null>(null);
const formExperience = ref<number>(0);
const formDescription = ref<string|null>(null);
const formAssignedInterviewers = ref<Client[]>([]);
const formQuestions = ref<Question[]>([]);
const formSocial = ref<Social|null>(!isEditing.value ? {email: client.email} : {});
const metroLoading = ref<boolean>(false);
const metroList = ref([]);
const uploadVideoList = useState<UploadVideoData[]>('uploadVideoList');
const itemInfoModalShow = ref<boolean>(false);
const stream = ref<MediaStream>();
const isCameraDenied = ref<boolean>(true);

//-----COMPUTED-----\\
const getCountChar = computed<number>(() => {
  return formDescription.value === null ? 0 : formDescription.value.length;
});
const getScheduleList = computed<string>(() => {
  if (formSchedules.value.length === 0) {
    return '';
  }

  let response = '';
  for (let i = 0; i < formSchedules.value.length; i++) {
    response += (i === 0 ? '' : ', ') + schedules.find(val => val.type === formSchedules.value[i]).name;
  }

  return response;
});
const isInformationValid = computed<boolean>(() => {
  return !($v.value.formCity.$invalid || $v.value.formProfession.$invalid || $v.value.formSchedules.$invalid);
});
const isRecordsValid = computed<boolean>(() => {
  for (let i = 0; i < formVacancyQuestions.value.length; i++) {
    if (formVacancyQuestions.value[i].recordBlob === null && formVacancyQuestions.value[i].answer === null) {
      return false;
    }
  }

  return true
});
const isAdditionalSettingsValid = computed<boolean>(() => {
  let tmp = false;
  socials.map((item) => {
    if ($v.value.formSocial[item.key].$invalid) {
      tmp = true;
      return;
    }
  });

  return !(/*$v.value.formAssignedInterviewers.$invalid ||*/ tmp || $v.value.formQuestions.$invalid);
});
const getVideoData = computed((): VideoData[] => {
  if (formVacancyQuestions.value.length === 0) {
    return [];
  }

  const videos: VideoData[] = [];
  for (let i = 0; i < formVacancyQuestions.value.length; i++) {
    const item = formVacancyQuestions.value[i];
    if (item.recordBlob === null && item.answer === null) {
      continue;
    }

    videos.push({
      src: item.recordBlob === null && item.answer !== null ? item.answer!.src : URL.createObjectURL(item.recordBlob),
      question: item.question,
      duration: 0,
      isAssembled: item.recordBlob === null ? true : (item.answer !== null ? item.answer!.isAssembled : true),
      isBlob: item.recordBlob !== null,
    });
  }

  return videos;
});
const isDirector = computed<boolean>(() => {
  for (let i = 0; i < client.companies.length; i++) {
    if (client.companies[i].id === companyId.value && client.companies[i].type) {
      return true;
    }
  }
  return false;
});

//-----ASYNC-----\\
if (activeTab.value === 1) {
  activateCamera();
}

if (isEditing.value) {
  await getVacancyData(vacancyId.value!);
  getMetro(false);
} else {
  await mainFetch('questions', {
    query: { type: 'vacancy' }
  }).then(response => {
    response = response.data.value??response.error.value?.data;
    if (response.error.code !== 200) {
      return;
    }

    // questions.value = response.questions;
    for (const i in response.questions) {
      const question = response.questions[i];
      formVacancyQuestions.value.push({
        id: question.id,
        question: question.question,
        hint: question.hint,
        position: question.position,
        type: question.type,
        time: question.time,
        isRequired: question.isRequired,
        answer: null,
        recordBlob: null,
      } as QuestionRecord);
    }
  });
  if (!isDirector.value) {
    formAssignedInterviewers.value.push(client.getClientEntity);
  }
}

//-----METHODS-----\\
async function activateCamera() {
  try {
    stream.value = await navigator.mediaDevices.getUserMedia(useVideoSettings(!isMobile));
    isCameraDenied.value = false;
  } catch (err) {
    $toast.error('Не удалось получить доступ к видеокамере и/или микрофону');
    isCameraDenied.value = true;
    return;
  }
}
function nextTab() {
  changeTab(activeTab.value + 1);
}
function changeTab(tab: number) {
  if (tab > activeTab.value) {
    if (activeTab.value === 0 && !isInformationValid.value) {
      $v.value.formCity.$touch();
      $v.value.formProfession.$touch();
      $v.value.formSchedules.$touch();

      return;
    } else if (activeTab.value === 1 && !isRecordsValid.value) {
      return;
    } else if (activeTab.value === 2 && !isAdditionalSettingsValid.value) {
      //$v.value.formAssignedInterviewers.$touch();
      $v.value.formQuestions.$touch();

      return;
    }
  }

  if (activeTab.value === 2) {
    socials.map((item) => {
      if (formSocial.value[item.key] === null) {
        delete formSocial.value[item.key];
      }
    });
  }

  if (isMobile) {
    const inputs = vacancyBlock.value.getElementsByTagName('input');
    for (let i = 0; i < inputs.length; i++) {
      inputs[i].blur();
    }
  }

  window.scrollTo(0, refPageTitle.value.getBoundingClientRect().top + window.scrollY);

  activeTab.value = tab;
  if (tab === 1 && isCameraDenied.value) {
    activateCamera();
  }
}
function check(type: number, isChecked: boolean) {
  if (isChecked) {
    formSchedules.value.push(type);
  } else {
    formSchedules.value.splice(formSchedules.value.indexOf(type), 1);
  }
}
function copyLink() {
  copy();
  if (copied) {
    $toast.success('Ссылка на вакансию скопирована');
  }
}
function getBreadcrumbs() {
  const breadcrumbs = [
    {
      text: 'Кабинет',
      alias: `/company/${route.params.id}/vacancies`
    }
  ];

  if (!isEditing.value) {
    breadcrumbs.push({text: 'Создание вакансии'});
    return breadcrumbs;
  }

  breadcrumbs.push(
    {
      text: `${formProfession.value!.title} ${formCity.value.title}`,
      alias: 'responses'
    },
    {
      text: 'Редактирование'
    }
  )
  return breadcrumbs;
}
async function getVacancyData(id: string) {
  const { data } = await mainFetch('/vacancies/' + id + '?get[companyQuestions]=true&get[assigned]=true');
  formCity.value = data.value.vacancy.city;
  formAddress.value = data.value.vacancy.address;
  if (data.value.vacancy.metroStation.length > 0) {
    formMetro.value = data.value.vacancy.metroStation[0];
  }
  formSalaryMin.value = data.value.vacancy.salary.min;
  formSalaryMax.value = data.value.vacancy.salary.max;
  formProfession.value = data.value.vacancy.profession;
  formDescription.value = data.value.vacancy.description;
  formQuestions.value = data.value.vacancy.companyQuestions;
  formVacancyQuestions.value = data.value.vacancy.questions.map(item => {
    return {
      ...item,
      recordBlob: null,
    };
  })??[];

  formSocial.value = data.value.vacancy.social === null || Object.keys(data.value.vacancy.social).length === 0 ? {} : data.value.vacancy.social;

  if (isDirector.value) {
    formAssignedInterviewers.value = data.value.vacancy.assignedInterviewers;
  } else {
    formAssignedInterviewers.value.push(client.getClientEntity);
  }

  isOnlyLink.value = data.value.vacancy.isOnlyLink;
  isArchived.value = data.value.vacancy.isArchived;
  isPublished.value = data.value.vacancy.isPublished;
  formExperience.value = data.value.vacancy.experience?.id;
  formTypeEmployment.value = data.value.vacancy.typeEmployment?.id;
  formSchedules.value = data.value.vacancy.schedules.map(val => val.type);
}
async function save() {
  if (isDirector.value) {
    let isFind = false;
    formAssignedInterviewers.value.forEach((item) => {
      if (item.id === client.getClientEntity.id) {
        isFind = true;
        return;
      }
    });

    if (!isFind) {
      formAssignedInterviewers.value.push(client.getClientEntity);
    }
  }
  $v.value.$touch();
  if ($v.value.$invalid) {
    changeTab($v.value.$errors.length === 1 && $v.value.$errors[0].$property === 'formAssignedInterviewers' ? 2 : 0);
    return;
  }

  if (formVacancyQuestions.value.findIndex(value => value.isRequired === true && value.recordBlob === null && value.answer === null) !== -1) {
    changeTab(1)
    return;
  }

  const newVideos = formVacancyQuestions.value.filter(value => value.recordBlob !== null);
  const isUploadVideo = newVideos.length !== 0;

  let isError = false;

  let newVacancyId = vacancyId.value;
  const isCreate = vacancyId.value === undefined;
  let recordToken = undefined;
  await mainFetch(isCreate ? 'vacancies' : `vacancies/${vacancyId.value}`, {
    method: isCreate ? 'POST' : 'PUT',
    body: await getDataForSave()
  }).then(response => {
    if (response.status.value !== "success") {
      return;
    }
    response = response.data.value;

    if (isCreate) {
      newVacancyId = response.vacancy.id;
    }

    if (isUploadVideo) {
      recordToken = response.recordToken;
      (response.records as object[]).forEach(value => {
        for (let i = 0; i < newVideos.length; i++) {
          if (value.questionId !== newVideos[i].id) {
            continue;
          }

          newVideos[i].answer = {
            id: value.answerId,
            src: null,
            isAssembled: false,
            created: Math.ceil((new Date()).getTime() / 1000),
          };
          break;
        }
      });
    }
  }).catch(() => {
    isError = true;
  });

  if (isError) {
    $toast.error('Ошибка');
    return;
  }

  const errVideoCount = newVideos.filter(value => value.answer === null).length;
  if (isUploadVideo) {
    if (errVideoCount !== 0) {
      $toast.error(`${errVideoCount}/${newVideos.length} видео не могут быть сохранены`);
    }

    if (errVideoCount !== newVideos.length) {
      for (let i = 0; i < newVideos.length; i++) {
        if (newVideos[0].answer === null) {
          continue;
        }

        uploadVideoList.value.push({
          token: recordToken,
          answerId: newVideos[i].answer!.id,
          isVacancy: true,
          file: newVideos[i].recordBlob!,
          isWebm: isWebmSupported,
          upload: {
            status: 0,
            size: {
              loaded: null,
              total: null,
            },
          },
        });
      }
    }
  }

  $toast.success(!isUploadVideo ? 'Успешно' : 'Успешно. Осталось лишь дождаться окончания загрузки видео.');

  router.push(`/company/${route.params.id}/vacancies/${newVacancyId}/responses`);
}
function getSalaryString(val:number | null) {
  return val === null ? '' : val.toLocaleString('ru-RU');
}
function getSalaryNumber (target:string) {
  if (target === '') {
    return null;
  }
  return target.replace(/[^\d]+/g, '') * 1;
}
async function getDataForSave() {
  return {
    company: companyId.value,
    city: formCity.value!.id,
    address: formAddress.value,
    metro: formMetro.value?.id??null,
    salary: formSalaryMin.value === null && formSalaryMax.value === null ? {min: null, max: null} : { min: formSalaryMin.value, max: formSalaryMax.value } as Salary,
    profession: formProfession.value!.id,
    description: formDescription.value,
    questions: formQuestions.value,
    videoAnswers: await Promise.all(formVacancyQuestions.value.filter(item => item.answer !== null || item.recordBlob !== null).map(async (item) => {
      return {
        id: item.answer?.id??null,
        question: item.id,
        hash: item.recordBlob === null ? null : await apiRecordUpload.getHash(item.recordBlob)
      };
    })),
    assignedInterviewers: formAssignedInterviewers.value.map(item => item.id),
    isOnlyLink: isOnlyLink.value,
    experience: formExperience.value,
    typeEmployment: formTypeEmployment.value,
    schedules: formSchedules.value,
    social: formSocial.value
  };
}
function addQuestion() {
  if (formQuestions.value.length === 5) {
    return;
  }

  formQuestions.value.push({
    id: '',
    question: '',
    time: 30
  });
}
function deleteQuestion(index:number) {
  formQuestions.value.splice(index, 1);
}
function archiving(restoreResponses: undefined|boolean = undefined) {
  isSending.value = true;
  if (vacancyId.value === undefined) {
    return;
  }

  apiVacancies.archiving(vacancyId.value, isPublished.value, restoreResponses).then(response => {
    response = response.data.value??response.error.value?.data;
    if (response.error.code === 409 && response.restoreResponses === true) {
      useRestoreResponsesModal.open();
      return;
    }

    if (response.error.code !== 200) {
      $toast.error('Ошибка');
      return;
    }

    // isArchived.value = !isArchived.value;
    isPublished.value = !isPublished.value;
    $toast.success(isPublished.value ? 'Вакансия опубликована' : 'Вакансия перенесена в архив');
  }).finally(() => {
    isSending.value = false;
    if (restoreResponses !== undefined) {
      useRestoreResponsesModal.close();
    }
  });
}
function setRecordBlob(index: number, value: Blob|null) {
  formVacancyQuestions.value[index].recordBlob = value;
  formVacancyQuestions.value[index].answer = null;
}
function startRecord(index: number) {
  formVacancyQuestions.value[index].answer = null;
}
function changeCompanyVideo(index: number, item: CompanyVideo|null) {
  if (item === null) {
    formVacancyQuestions.value[index].answer = null;
    return;
  }

  formVacancyQuestions.value[index].answer = item;
}
function getMetro(clear: boolean = true) {
  if (formCity.value === null) {
    return;
  }

  if (clear) {
    formMetro.value = null;
  }

  metroLoading.value = true;
  metroList.value = [];
  mainFetch('metro/' + formCity.value.id).then(({ data, status }) => {
    if (status.value !== "success") {
      return;
    }

    data.value.metroLines.forEach((line) => {
      line.stations.forEach((station) => {
        metroList.value.push({
          id: station.id,
          name: station.name,
          line: {
            id: line.id,
            name: line.name,
            color: line.color,
          },
        });
      })
    });
  }).finally(() => {
    metroLoading.value = false;
  });
}
function setWebmSupported(val: boolean) {
  isWebmSupported = val;
}

const $v = useVuelidate({
  formCity: { required },
  formProfession: { required },
  formExperience: { required },
  formSchedules: { required, minLength: minLength(1) },
  formTypeEmployment: { required },
  formSalaryMin: { numeric },
  formSalaryMax: {
    minCheck(val: number|null): boolean {
      return val === null || ((val * 1) >= 0 && (formSalaryMin.value === null || formSalaryMin.value * 1) <= (val * 1));
    }
  },
  formAssignedInterviewers: { required },
  formDescription: { maxLength: maxLength(maxChar) },
  formQuestions: {
    checkQuestion(val: Question[]): boolean {
      if (val.length !== 0) {
        for (let i = 0; i < val.length; i++) {
          if (val[i].question === '') {
            return false;
          }
        }
      }

      return true;
    },
  },
  formSocial: {
    email: { email },
    phone: { phone },
    telegram: { phone },
    whatsapp: { phone },
    viber: { phone },
  }
}, {
  formCity,
  formProfession,
  formExperience,
  formTypeEmployment,
  formSchedules,
  formSalaryMin,
  formSalaryMax,
  formAssignedInterviewers,
  formDescription,
  formQuestions,
  formSocial
});

onMounted(() => {
  isMobile = useCheckMobile();

  if (isMobile) {
    wrapHeight = window.innerHeight;
    wrapWidth = window.innerWidth;
  } else {
    wrapHeight = window.innerHeight * 0.8;
    wrapWidth = (720 / 1280) * wrapHeight;
  }
});
</script>

<style lang="scss">
//@import 'vue-toastification/src/scss/index';
</style>
